import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "flex mb-1" }
const _hoisted_4 = { class: "font-bold break-all mr-4" }
const _hoisted_5 = { class: "ml-auto text-sm font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bg-opacity-30 p-5 rounded-md mb-5", {
      'bg-red-300 text-red-900': _ctx.errorMessage,
      'bg-green-300 text-green-900': !_ctx.errorMessage,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Icon, {
        class: "mr-3",
        type: _ctx.iconType
      }, null, 8, ["type"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.logItem.itemName || _ctx.errorTitle), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.localTime), 1)
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.errorMessage || _ctx.message), 1)
      ])
    ])
  ], 2))
}