import { FAQItem } from '@/types';
import { ErrorType } from '@/enums';
import { OPENHAB_URL } from '@/config';

export default {
  general: {
    open: 'Open',
    close: 'Close',
    virtualPUXlab: 'Virtual PUX Lab',
    virtualPUXlabDescription: `The virtual PUX lab is an interactive virtual living room with lamps and lightstrips 
    representing a virtual version of the real PUX lab at the Hochschule der Medien in Stuttgart.`,
    fullScreen: 'Full Screen',
    expandLabView: 'Expand virtual lab to full screen mode',
  },
  intro: {
    title: 'Welcome to the Virtual PUX Lab!',
    paragraph1: `The virtual PUX lab is a virtualised version of the real PUX Lab of the
    Hochschule der Medien Stuttgart. In this lab styled like a typical
    living room, you can find several smart home devices, e.g. smart light
    systems like Philips Hue or Ikea Trådfri.`,
    paragraph2: `The goal of this lab is to learn how to control smart home devices
    through the OpenHAB API. The virtualised OpenHAB server can be
    accessed at the following address:`,
    paragraph3: `The room ID that you can see below can be shared with other students to
    work within the same virtual lab. Use it like the API token (Basic Auth) in your
    OpenHAB requests.`,
    paragraph4: `To control the lightbulbs, just send the according HTTP request to the
    virtual OpenHAB server. You can e.g. change the light color or the
    brightness and see the effect in the virtualised PUX lab. To get
    information about the devices, just hover over the objects in the virtual lab
    or have a look at the "Things & Items" list.`,
    helpSectionHint: [
      "You don't have experience with OpenHAB yet?",
      'Take a look at the',
      'help section',
      'in the top right.',
    ],
  },
  helpSection: {
    title: 'Help Section',
    help: 'Help',
    introText: `In this section you can find some resources that should help you understand how to use 
      this virtual lab.`,
    video: 'Video',
    videoText: `This video should give you a first impression of the virtual lab and show you how to use it.`,
    faq: 'FAQ',
    expandableContentOf: 'expandable content of',
    faqItems: [
      {
        title: 'How do I control the lamps?',
        body: `In order to change the state of the lamps, a corresponding request must be sent to the virtual 
        OpenHAB server. This can come, for example, from your own developed smart home application.<br/><br/>
        It is important that the room ID is included as an API token in the authentication header in the Basic 
        Auth format (token as username, password empty) within your request, so that you can also see the 
        changed state in your virtual PUX lab instance.<br/><br/>
        To control a certain lamp (thing) you need an associated item, for example an item of the type
        "dimmer" with the channel type "brightness", with which you can change the brightness of a lamp. Go to 
        the <a href="https://www.openhab.org/docs/concepts/items.html#items" target="blank" class="underline">
        OpenHAB documentation</a> to find out which commands are allowed per item type.
        <br/><br/>
        
        <strong>Example to decrease the brightness of the HueLampLittleTable lamp:</strong><br/>
        <code class="font-mono">
          curl -X POST --header "Content-Type: text/plain" --header "Accept: application/json" -d "DECREASE"
          "${OPENHAB_URL}/rest/items/HueLampLittleTable_Brightness" -u "&lt;API_TOKEN&gt;:"s
        </code>

        <br/><br/>
        <strong>Example to change the color of the HueLampFrontLeft lamp with the HSB format (hue, saturation, brightness) 
        to a strong red:</strong><br/>
        <code class="font-mono">
          curl -X POST --header "Content-Type: text/plain" --header "Accept: application/json" -d "0,100,100"
          "${OPENHAB_URL}/rest/items/HueLampFrontLeft_Color" -u "&lt;API_TOKEN&gt;:"
        </code>
        `,
      },
      {
        title: 'What is the room ID and how should I use it?',
        body: `The room ID is a way to identify the living room instance in which you are working. When 
        joining the course for the first time, you will get a unique room ID. This ID will be persisted, so 
        that you can come back to the according lab instance and its state at any time.<br/><br/>
        The room ID can be shared with other students to work together within the same room. Therefore 
        the room ID can be changed, self-created IDs won't work though, they have to be generated by 
        the system.<br/><br/>
        It is also possible to generate new IDs / tokens to start within a newly, freshly created virtual 
        lab state. This can be helpful to be able to work in parallel in multiple rooms and to separate 
        those states (e.g. you can have a group room and an individual room) Therefore, it would make 
        sense to save those room IDs somewhere separately, in order to return to a specific room.<br/><br/>
        When you want to control the lamps in your virtual lab identified by the room ID, just use the 
        room ID as the API token (Basic Auth) within your OpenHAB request.`,
      },
      {
        title: 'Where do I find the OpenHAB API Documentation?',
        body: `Go to the <a href="https://demo.openhab.org/developer/api-explorer/" target="blank" 
        class="underline">OpenHAB Demo</a> and log in (lock icon "Unlock Administration" at the bottom 
        of the menu sidebar) as the demo user ("demo" for username and password). After that you can 
        find the menu item "Developer Tools" ➔ "API Explorer" on the left.`,
      },
      {
        title: 'What is the virtual OpenHAB Server?',
        body: `The virtual OpenHAB server should simulate the real OpenHAB server of the PUX lab. By sending 
        the according requests to the virtual OpenHAB server, you can control the lamps within the virtual 
        PUX lab. The following REST-endpoints of the virtual OpenHAB server exists:
        <br/><br/>
        <ul>
        <li> -  GET <span class="font-mono">/rest/things</span> </li>
        <li> - GET <span class="font-mono">/rest/items</span> </li>
        <li> - POST <span class="font-mono">/rest/item/:itemName</span> </li>
        </ul>
        <br/>
        Please mind that the real OpenHAB server can only be reached via HTTP, the virtual OpenHAB server
        however is encrypted and can be reached via HTTPS.`,
      },
      {
        title: 'What are Things and Items?',
        body: `Things and Items are entitites of OpenHAB. You should get to know them, in order to be able to 
        interact with the virtual lab and to control its lamps. Just have a look at the 
        <a href="https://www.openhab.org/docs/" target="blank" class="underline">OpenHAB documentation</a> to 
        understand the logic. There are some examples about how to interact with the items to change lamp states 
        in the answer of the next question.
        <br/><br/>
        <ul>
        <li>- <strong>Things:</strong> Represent the physical devices (e.g. HueLampLittleTable).</li>
        <li>- <strong>Items:</strong> Represent information about the devices (e.g. the state) and define how 
        the devices can be controlled (e.g. type "dimmer" allows percentage values or type "color" expects 
        values in the HSB format).</li>
        <li>- <strong>Channels:</strong> Represent the connection between things and items and enable the control 
        of specific functionalities of the devices (e.g. type "brightness" or "color").</li>
        </ul>
        `,
      },
      {
        title: 'What are Item Groups?',
        body: `Item Groups are Items that include other Items. For this reason, Item Groups can be used to control 
        several Items and thus several lamps at the same time. For example, it is possible to set the brightness 
        of all lamps to the same value with the AllLampsAndLightstrips_Brightness Item Group.
        `,
      },
      {
        title: 'How can I interact with the virtual lab?',
        body: `There are different areas of the virtual lab that offer different information and interactions:
        <br/><br/>
        <ul>
        <li>
          <strong>Change Language:</strong> In the upper left corner you will find an option to switch the language 
          between English and German. OpenHAB terms like Thing, Item and State etc. were deliberately not translated.
        </li>
        <br/>
        <li>
          <strong>Introduction:</strong> At the top you will find an introduction to the virtual PUX lab, which 
          briefly explains what the virtual PUX lab is and how it can be used. 
        </li>
        <br/>
        <li>
          <strong>Text Fields:</strong> Below the introduction text you will find two text fields. The first one 
          holds the address of the virtual OpenHAB server, to which requests can be sent. The address can be 
          copied, but not changed.<br/>
          The second text field contains the room ID which should be used as API token (Basic Auth) in the OpenHAB requests 
          in order to uniquely identify the current virtual PUX lab instance. The ID will be persisted so 
          that you can return to the respective lab instance and its state at any time. The room ID can be shared 
          with others and changed in order to work in the same virtual room. However, it must be a system generated ID.
          It is also possible to generate new IDs / tokens to start within a newly, freshly created virtual 
          lab state. This can be helpful to be able to work in parallel in multiple rooms and to separate 
          those states (e.g. you can have a group room and an individual room) Therefore, it would make 
          sense to save those room IDs somewhere separately, in order to later return to a specific room.
        </li>
        <br/>
        <li>
          <strong>Depiction of the Virtual Lab:</strong> The main area of the virtual PUX lab is its virtual depiction. 
          You can visually view the state of the lamps in you virtual PUX lab instance. If you hover over the lamps, you will 
          get the name and the status of the lamp. To get further important information,such as the corresponding items 
          to control the lamp, click on the lamp and you will automatically be taken to the highlighted thing and its items in 
          the list.<br/>
          Since an exact representation of the lamp states in a virtual space is not possible, a brief explanation about the 
          depiction of some special states are given: <br/>
          <ul>
          <li>- When a lamp is off, it has no glow and the lampshade is filled light gray.</li>
          <li>- The lower the brightness, the more transparent the lampshade as well as the glow.</li>
          <li>- Lamps where only the brightness can be controlled, but not the color, are shown with a yellowish 
          color and a yellow glow, since a white glow would not be well visible in front of a white background.</li>
          <li>- Unfortunately, items with the channel type "color temperature" cannot be controlled or displayed in the virtual 
          lab, which means the change of these states has no effect on the display.</li>
          </ul>
        </li>
        <br/>
        <li>
          <strong>Things & Items List:</strong> This list contains textual information about the OpenHAB Things and the associated 
          Items. Here you can find all the information that is important and relevant for controlling the lamps. Moreover, 
          the exact state can be viewed in textual form in addition to the visual representation in the depiction above.
        </li>
        <br/>
        <li>
          <strong>Action Log:</strong> Requests that change the state of the items are logged in the action log. Every log records 
          when and how the state of which item has been changed with which value. In addition, failed requests are logged as well. By 
          clicking on the "Clear"-button the current log items will be deleted.
        </li>
        <br/>
        <li>
          <strong>Help Section:</strong>This is the area where you currently are. Here you will find some important resources that 
          might help you to use the virtual PUX lab, e.g. an explanatory video that shows you all the interaction possibilities 
          and functionalities.
        </li>
        </ul>`,
      },
      {
        title: 'What is the action log?',
        body: `Requests that change the state of the items are logged in the action log. Only the maximum of the 30 latest ones are 
        displayed. Every log records when and how the state of which item has been changed with which value. In addition, failed requests 
        are logged as well. The action log is not persisted which means it will be emptied on page reload.`,
      },
    ] as FAQItem[],
    otherResources: 'Other Resources',
    website: 'Website',
    documentation: 'Documentation',
  },
  actionLog: {
    title: 'Action Log',
    titleTooltip: `Requests that change the state of the items are logged in the action log. Every log records 
    when and how the state of which item has been changed with which value. In addition, failed requests are logged as well. By 
    clicking on the "Clear"-button the current log items will be deleted.`,
    noItems: 'No action log items available.',
    lampSingularMessage: 'Lamp was',
    lampPluralMessage: 'Lamps were',
    lampTurnedOnMessage: 'turned on',
    lampTurnedOffMessage: 'turned off',
    brightnessIncreaseMessage: 'Brightness increased to',
    brightnessDecreaseMessage: 'Brightness decreased to',
    brightnessChangeMessage: 'Brightness changed to',
    colorChangeMessage: 'Color changed to',
    clear: 'Clear',
    clearActionLog: '@:actionLog.clear action log',
  },
  items: {
    colorTemperatureItemHint: `Items of channel type "Color Temperature" can not be controlled
     in the virtual PUX lab.`,
    itemName: 'Item name',
    itemGroupName: 'Item group name',
    copyItemName: 'Copy @:items.itemName {itemName}',
    copyItemGroupName: 'Copy @:items.itemGroupName {itemGroupName}',
    goToItemGroup: 'Go to item group {itemGroup}',
    listTitleTooltip: `This list contains textual information about the OpenHAB Things and the associated 
    Items. Here you can find all the information that is important and relevant for controlling the lamps. Moreover, 
    the exact state can be viewed in textual form in addition to the visual representation in the depiction above.`,
    itemGroupTooltip: `Item Groups are items that include other items. Accordingly, item groups can be used to control 
    several items and thus several lamps at the same time. For example, it is possible to set the brightness of all 
    lamps to the same value with the AllLampsAndLightstrips_Brightness item group.`,
  },
  openHabInfo: {
    roomId: 'Room ID',
    apiToken: 'API token',
    whatIsThis: 'What is this?',
    newRoomButtonLabel: 'New Room',
    enterRoomButtonLabel: 'Enter Room',
    roomAlreadyEntered: 'You are already in the room with the specified ID.',
    roomIdHint: `The room id is a unique identifier for the virtual lab instance you are working in. 
    Use it as the API token (Basic Auth) within your OpenHAB requests. You can share the id with others to work together.`,
    openHabServerHint: `Hint: The virtual OpenHAB server is only accessible via HTTPS. Requests to the real
    OpenHAB server in the PUX lab must be sent to its according address and accessed via HTTP.`,
    virtualLabel: 'Virtual',
    copied: 'copied',
    created: 'created',
    roomEntered: 'Room entered!',
    copyInputText: 'Copy input text of {inputLabel}',
    invalidRoomId: 'Invalid Room ID!',
  },
  languageSwitch: {
    en: 'English',
    de: 'German',
    languageDropdown: 'language select dropdown',
    changeLanguageTo: 'Change language to',
  },
  errors: {
    error: 'Error',
    [ErrorType.CONNECTION_ERROR]:
      'Connection Error: Unable to fetch data of virtual lab.',
    [ErrorType.INVALID_ROOM_ID]:
      'Invalid Room ID: Please use valid IDs created by the system or create a new room.',
    [ErrorType.ITEM_NOT_FOUND]: 'Item not found:',
    [ErrorType.INVALID_COMMAND]: 'Invalid command:',
  },
  footer: {
    nav: "Footer",
    imprint: "Imprint",
    privacy: "Privacy",
    ezb: "Accessibility",
  },
};
