
import { defineComponent } from 'vue';
import { OPENHAB_URL } from '@/config';

import { emitter } from '@/events';

export default defineComponent({
  name: 'Intro',
  data() {
    return {
      OPENHAB_URL,
    };
  },

  methods: {
    openHelp() {
      emitter.emit('open-help-section');
    },
  },
});
