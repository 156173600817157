import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex gap-1" }
const _hoisted_2 = ["aria-label", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.itemGroupIcons, (itemGroupIcon, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("button", {
        "aria-label": _ctx.$t('items.goToItemGroup', { itemGroup: $props.groups[index] }),
        key: itemGroupIcon,
        class: "cursor-pointer",
        onClick: ($event: any) => ($options.highlightItemInList($data.IconToItemGroupNameMapping[itemGroupIcon]))
      }, [
        _createVNode(_component_Icon, { type: itemGroupIcon }, null, 8, ["type"])
      ], 8, _hoisted_2)), [
        [_directive_tippy, { content: $data.IconToItemGroupNameMapping[itemGroupIcon] }]
      ])
    }), 128))
  ]))
}