
import { defineComponent, PropType } from 'vue';
import { ActionLogItem } from '@/models/ActionLogItem';
import Icon from './Icon.vue';
import { IconType, StateChangeType } from '@/enums';
import { ActionLogItemType } from '@/enums';

export default defineComponent({
  name: 'ActionLogItem',
  components: { Icon },
  props: {
    logItem: {
      type: Object as PropType<ActionLogItem>,
      required: true,
    },
  },
  data() {
    return {
      ActionLogItemType,
    };
  },
  computed: {
    errorTitle(): string {
      return this.$t('errors.error');
    },
    errorMessage(): string | null {
      return this.logItem.error
        ? `${this.$t(`errors.${this.logItem.error.type}`)} ${
            this.logItem.error.info
          }`
        : null;
    },
    iconType(): IconType {
      return this.logItem.error ? IconType.ERROR : IconType.SUCCESS;
    },
    message(): string {
      const lampText = this.logItem.itemName?.startsWith('All')
        ? this.$t('actionLog.lampPluralMessage')
        : this.$t('actionLog.lampSingularMessage');

      switch (this.logItem.stateChangeType) {
        case StateChangeType.ON:
          return `${lampText} ${this.$t('actionLog.lampTurnedOnMessage')}.`;
        case StateChangeType.OFF:
          return `${lampText} ${this.$t('actionLog.lampTurnedOffMessage')}.`;
        case StateChangeType.INCREASE:
          return `${this.$t('actionLog.brightnessIncreaseMessage')} ${
            this.logItem.state
          } %.`;
        case StateChangeType.DECREASE:
          return `${this.$t('actionLog.brightnessDecreaseMessage')} ${
            this.logItem.state
          } %.`;
        case StateChangeType.BRIGHTNESS:
          return `${this.$t('actionLog.brightnessChangeMessage')} ${
            this.logItem.state
          } %.`;
        case StateChangeType.COLOR:
          return `${this.$t('actionLog.colorChangeMessage')} HSB(${
            this.logItem.state
          }).`;
        default:
          return '';
      }
    },
    localTime(): string {
      return new Date(this.logItem.timestamp)
        .toLocaleTimeString()
        .split(':')
        .slice(0, 2)
        .join(':');
    },
  },
});
