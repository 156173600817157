
import { defineComponent } from 'vue';
import { IconType } from '@/enums';
import Icon from './Icon.vue';

export default defineComponent({
  name: 'ExpandableItem',
  components: { Icon },
  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      shouldShowBody: false,
      IconType,
    };
  },
  methods: {
    toggleShouldShowBody() {
      this.shouldShowBody = !this.shouldShowBody;
    },
  },
});
