
import { defineComponent } from 'vue';
import Icon from './Icon.vue';
import { IconType, Language } from '@/enums';

export default defineComponent({
  name: 'LanguageSwitch',
  components: { Icon },
  data() {
    return {
      IconType,
      Language,
      languageSelectIsShown: false,
    };
  },
  methods: {
    showLanguageSelect() {
      this.languageSelectIsShown = !this.languageSelectIsShown;
    },
    changeLanguage(language: string) {
      this.$i18n.locale = language;
      this.languageSelectIsShown = false;
      document.querySelector('html')?.setAttribute('lang', language);
    },
  },
  computed: {
    languageLabel(): string {
      return this.$t(`languageSwitch.${this.$i18n.locale}`);
    },
  },
});
