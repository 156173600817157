import { createApp } from 'vue';
import App from './App.vue';
import 'tailwindcss/tailwind.css';
import '@/assets/style.css';
import Icon from '@/components/Icon.vue';
import { createI18n } from 'vue-i18n';
import translations from '@/translations';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { OPENHAB_URL } from './config';

const app = createApp(App);

const i18n = createI18n({
  locale: 'de', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: translations, // set locale messages
  warnHtmlInMessage: 'off',
});
app.use(i18n);

app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>
  }
);

app.use(Toast, {
  position: POSITION.BOTTOM_CENTER,
  transition: 'Vue-Toastification__fade',
});

const socket = io(OPENHAB_URL);
app.use(VueSocketIOExt, socket);

app.mount('#app');
