
import { defineComponent } from 'vue';
import ActionLogItem from './ActionLogItem.vue';
import { StateChangeType } from '@/enums';
import { CreateActionLogItemInfo } from '@/types';
import { ActionLogItem as LogItem } from '@/models/ActionLogItem';
import { IconType } from '@/enums';
import { emitter } from '@/events';
import { Handler } from 'mitt';
import AppButton from './Button.vue';
import Icon from './Icon.vue';

export default defineComponent({
  name: 'ActionLog',
  components: {
    ActionLogItem,
    AppButton,
    Icon,
  },
  created() {
    emitter.on('new-action-log-item', this.addNewActionLogItem as Handler);
    emitter.on('clear-action-log', this.clearActionLog as Handler);
  },
  beforeUnmount() {
    emitter.off('new-action-log-item', this.addNewActionLogItem as Handler);
    emitter.off('clear-action-log', this.clearActionLog as Handler);
  },
  data() {
    return {
      IconType,
      logItems: [] as LogItem[],
    };
  },
  computed: {
    visibleLogItems(): LogItem[] {
      return this.logItems.slice(0, 30);
    },
  },
  methods: {
    clearActionLog() {
      this.logItems = [];
    },
    addNewActionLogItem({
      timestamp,
      itemName,
      stateChangeType,
      state,
      error,
    }: CreateActionLogItemInfo) {
      if (
        stateChangeType &&
        stateChangeType !== StateChangeType.COLOR &&
        state &&
        state?.length > 3
      ) {
        state = state.split(',')[2];
      }

      const newActionLogItem = new LogItem(
        timestamp,
        itemName,
        stateChangeType,
        state,
        error
      );

      this.logItems.unshift(newActionLogItem);
    },
  },
});
