
import { defineComponent } from 'vue';
import { IconType } from '@/enums';
import Icon from './Icon.vue';

export default defineComponent({
  name: 'ExternalLink',
  components: { Icon },
  props: {
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      IconType,
    };
  },
});
