import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-7"
}
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "ml-auto" }
const _hoisted_4 = { class: "ml-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Item = _resolveComponent("Item")!

  return (_ctx.thing.items.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "flex bg-gray-200 px-5 py-4 rounded-md cursor-pointer",
          tabindex: "0",
          ref: "thing",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.thing.label), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Icon, {
              type: _ctx.IconType.CHEVRON_DOWN,
              class: _normalizeClass(["transform transition", {
            'rotate-180': _ctx.isOpen,
          }])
            }, null, 8, ["type", "class"])
          ])
        ], 512),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.thing.items, (item) => {
            return (_openBlock(), _createBlock(_component_Item, {
              key: item.name,
              item: item
            }, null, 8, ["item"]))
          }), 128))
        ], 512), [
          [_vShow, _ctx.isOpen]
        ])
      ]))
    : _createCommentVNode("", true)
}