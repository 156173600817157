
import { defineComponent } from 'vue';
import Intro from './components/Intro.vue';
import VirtualLabView from './components/VirtualLabView.vue';
import ThingList from './components/ThingList.vue';
import ActionLog from './components/ActionLog.vue';
import LanguageSwitch from './components/LanguageSwitch.vue';
import OpenHabInfo from './components/OpenHabInfo.vue';
import HelpSection from './components/HelpSection.vue';
import { init } from '@/api';
import {
  ActionLogInfo,
  APIResponse,
  ErrorResponse,
  Item,
  Thing,
} from './types';
import { emitter } from '@/events';
import { Handler } from 'mitt';
import { ErrorType, IconType } from './enums';
import { useToast } from 'vue-toastification';
import AppButton from '@/components/Button.vue';

export default defineComponent({
  name: 'App',
  components: {
    Intro,
    VirtualLabView,
    ThingList,
    ActionLog,
    LanguageSwitch,
    OpenHabInfo,
    HelpSection,
    AppButton,
  },
  sockets: {
    connect() {
      console.log('socket connected');
    },
    actionLogMessage(updateInfo: ActionLogInfo) {
      this.updateLabState(updateInfo);
    },
  },
  data() {
    return {
      token: '',
      things: [] as Thing[],
      itemGroups: [] as Item[],
      error: null as ErrorType | null,
      toast: useToast(),
      IconType,
    };
  },
  created() {
    this.setupLab();
    emitter.on('token-change', this.updateToken as Handler);
  },
  computed: {
    lamps(): Thing[] {
      return this.things.slice(0, 9);
    },
    errorMessage(): string {
      return this.$t(`errors.${this.error}`);
    },
  },
  methods: {
    async setupLab(isCreateNewRoomEvent = false) {
      let token;

      if (!isCreateNewRoomEvent) {
        token = localStorage.getItem('room-token') || undefined;
        this.token = token || '';
      }

      try {
        const data = await init(token);

        if ((data as ErrorResponse).error) {
          switch ((data as ErrorResponse).error) {
            case ErrorType.INVALID_ROOM_ID:
              this.error = ErrorType.INVALID_ROOM_ID;
              this.toast.error(this.$t('openHabInfo.invalidRoomId'));
              break;
            default:
              this.error = ErrorType.CONNECTION_ERROR;
          }

          return;
        }

        this.error = null;

        if (!isCreateNewRoomEvent) {
          this.toast.success(this.$t('openHabInfo.roomEntered'));
        }

        if (!token) {
          localStorage.setItem('room-token', (data as APIResponse).token);
          this.token = (data as APIResponse).token;
        }

        this.$socket.client.emit('join-room', this.token);
        emitter.emit('clear-action-log');

        this.things = (data as APIResponse).things;
        this.itemGroups = (data as APIResponse).itemGroups;
      } catch (e) {
        this.error = ErrorType.CONNECTION_ERROR;
      }
    },
    updateLabState({
      timestamp,
      error,
      itemName,
      stateChangeType,
      state,
      withoutActionLogItem,
    }: ActionLogInfo) {
      let item;

      this.things.some((t) => {
        item = t.items.find((i: Item) => i.name === itemName);
        if (item) {
          return true;
        }
      });

      if (!item) {
        item = this.itemGroups.find((ig) => ig.name === itemName);
      }

      if (item && state) {
        item.state = state;
      }

      if (!withoutActionLogItem) {
        emitter.emit('new-action-log-item', {
          timestamp,
          itemName,
          stateChangeType,
          state,
          error,
        });
      }
    },
    updateToken(newToken: string) {
      this.token = newToken;
      localStorage.setItem('room-token', newToken);
      if (!newToken) {
        this.error = ErrorType.INVALID_ROOM_ID;
        this.toast.error(this.$t('openHabInfo.invalidRoomId'));
        return;
      }
      this.setupLab();
    },
    expandLabView(): void {
      const labView: HTMLElement = (this.$refs.labView as any).$el;
      labView.requestFullscreen();
    },
  },
});
