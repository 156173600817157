
import { defineComponent, PropType } from 'vue';
import { Item } from '@/types';
import { IconType } from '@/enums';
import { emitter } from '@/events';
import { Handler } from 'mitt';
import Clipboard from 'clipboard';
import { useToast } from 'vue-toastification';
import { IconToItemGroupNameMapping, scrollToElement } from '@/utils';
import Icon from './Icon.vue';
import GroupIcons from './GroupIcons.vue';

export default defineComponent({
  name: 'ItemGroup',
  components: { Icon, GroupIcons },
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
  },
  data() {
    return {
      IconType,
      IconToItemGroupNameMapping,
      isHighlighted: false,
      clipboard: null as Clipboard | null,
    };
  },
  mounted() {
    const toast = useToast();

    if (!this.item.name.includes('ColorTemperature')) {
      this.clipboard = new Clipboard(this.$refs.copyButton as HTMLElement);
    }

    this.clipboard?.on('success', () => {
      toast.success(
        `${this.$t('items.itemGroupName')} ${this.$t('openHabInfo.copied')}!`
      );
    });
  },
  methods: {
    highlightItemHandler(itemName: string) {
      const isHighlighted = this.item.name === itemName;
      if (isHighlighted) {
        scrollToElement(this.$refs.item as HTMLElement, 20);
        (this.$refs.item as HTMLElement).focus({ preventScroll: true });
      }
    },
  },
  computed: {
    itemGroupIcon(): string | undefined {
      return Object.keys(IconToItemGroupNameMapping).find(
        (key) => (IconToItemGroupNameMapping as any)[key] === this.item.name
      );
    },
  },
  created() {
    emitter.on('highlight-item', this.highlightItemHandler as Handler);
  },
  beforeUnmount() {
    emitter.off('highlight-item', this.highlightItemHandler as Handler);
  },
});
