
import { Thing } from '@/types';
import { defineComponent, PropType } from 'vue';
import Lamp from './Lamp.vue';
import { LampLabels } from '@/enums';

export default defineComponent({
  name: 'VirtualLabView',
  components: {
    Lamp,
  },
  data() {
    return {
      LampLabels,
    };
  },
  props: {
    lamps: {
      type: Object as PropType<Thing[]>,
      required: true,
    },
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${require('@/assets/brick-background.svg')})`,
        backgroundSize: '500px',
      };
    },
    lampsByLabel(): { [key: string]: Thing } {
      return this.lamps.reduce((acc, curr) => {
        acc[curr.label] = curr;
        return acc;
      }, {} as { [key: string]: Thing });
    },
  },
});
