
import { defineComponent } from 'vue';
import Icon from './Icon.vue';
import { IconType } from '@/enums';
import ExpandableItem from './ExpandableItem.vue';
import { FAQItem, ExternalLink as LinkType } from '@/types';
import ExternalLink from './ExternalLink.vue';
import AppButton from './Button.vue';
import { emitter } from '@/events';
import { OPENHAB_URL } from '@/config';

export default defineComponent({
  name: 'HelpSection',
  components: { Icon, ExpandableItem, ExternalLink, AppButton },
  data() {
    return {
      IconType,
      helpSectionIsShown: false,
      OPENHAB_URL,
    };
  },
  mounted() {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = this.$refs.modal as HTMLElement;

    const firstFocusableElement = modal.querySelectorAll(
      focusableElements
    )[0] as HTMLElement;
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[
      focusableContent.length - 1
    ] as HTMLElement;

    document.addEventListener('keydown', (e) => {
      let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    });
  },
  computed: {
    faqItems(): FAQItem[] {
      return this.$tm('helpSection.faqItems') as FAQItem[];
    },
    externalLinks(): LinkType[] {
      return [
        {
          title: `OpenHAB ${this.$t('helpSection.website')}`,
          link: 'https://www.openhab.org/',
        },
        {
          title: `OpenHAB ${this.$t('helpSection.documentation')}`,
          link: 'https://www.openhab.org/docs/',
        },
        {
          title: 'OpenHAB Demo',
          link: 'https://demo.openhab.org/',
        },
      ];
    },
  },
  methods: {
    openHelpSection() {
      this.helpSectionIsShown = true;
      this.$nextTick(() => {
        (this.$refs.closeHelpSectionButton as HTMLElement).focus();
      });
    },
    closeHelpSection() {
      this.helpSectionIsShown = false;
    },
  },

  created() {
    emitter.on('open-help-section', this.openHelpSection);
  },

  beforeUnmount() {
    emitter.off('open-help-section', this.openHelpSection);
  },
});
