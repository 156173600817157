
import { defineComponent } from 'vue';
import Clipboard from 'clipboard';
import { IconType } from '@/enums';
import { useToast } from 'vue-toastification';
import Icon from './Icon.vue';

export default defineComponent({
  name: 'AppInput',
  components: { Icon },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    copyToastMessage: {
      type: String,
    },
  },
  data() {
    return {
      IconType,
      clipboard: null as Clipboard | null,
    };
  },
  mounted() {
    const toast = useToast();

    this.clipboard = new Clipboard(this.$refs.copyButton as HTMLElement);
    this.clipboard.on('success', (e) => {
      toast.success(this.copyToastMessage || this.defaultCopyToastMessage);
    });
  },
  computed: {
    defaultCopyToastMessage(): string {
      return `${this.$t('openHabInfo.copied')}!`;
    },
    inputId(): string {
      return performance.now().toString().replace('.', '');
    },
  },
});
