
import { defineComponent, PropType } from 'vue';
import { Item as ItemType } from '@/types';
import { IconType, ChannelType } from '@/enums';
import { getHexColor, getIsOff, getOpacity } from '@/utils';
import Clipboard from 'clipboard';
import { useToast } from 'vue-toastification';
import Icon from './Icon.vue';
import GroupIcons from './GroupIcons.vue';

export default defineComponent({
  name: 'Thing',
  components: { Icon, GroupIcons },
  props: {
    item: {
      type: Object as PropType<ItemType>,
      required: true,
    },
  },
  mounted() {
    const toast = useToast();

    if (this.item.channelType !== ChannelType.COLOR_TEMPERATURE) {
      this.clipboard = new Clipboard(this.$refs.copyButton as HTMLElement);
    }

    this.clipboard?.on('success', () => {
      toast.success(
        `${this.$t('items.itemName')} ${this.$t('openHabInfo.copied')}!`
      );
    });
  },
  data() {
    return {
      IconType,
      ChannelType,
      clipboard: null as Clipboard | null,
    };
  },
  computed: {
    hexColor(): string {
      return getHexColor(this.item);
    },
    opacity(): number {
      return getOpacity(this.item);
    },
    isOff(): boolean {
      return getIsOff(this.item);
    },
  },
});
