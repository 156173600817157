
import { defineComponent, PropType } from 'vue';
import { IconType } from '@/enums';

export default defineComponent({
  name: 'Icon',
  props: {
    type: {
      type: String as PropType<IconType>,
      validator: (v: IconType) => Object.values(IconType).includes(v),
    },
  },
  data() {
    return {
      IconType,
      id: Math.ceil(Math.random()*1000000),
    };
  },
});
