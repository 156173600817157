
import { defineComponent } from 'vue';
import AppInput from './Input.vue';
import Icon from './Icon.vue';
import { IconType } from '@/enums';
import { emitter } from '@/events';
import { OPENHAB_URL } from '@/config';
import { useToast } from 'vue-toastification';
import AppButton from './Button.vue';

export default defineComponent({
  name: 'OpenHabInfo',
  components: { AppInput, Icon, AppButton },
  emits: ['create-new-room'],
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      IconType,
      OPENHAB_URL,
      toast: useToast(),
      currentToken: this.token,
    };
  },
  methods: {
    createNewRoom() {
      this.$emit('create-new-room');
      this.toast.success(
        `${this.$t('openHabInfo.newRoomButtonLabel')} ${this.$t(
          'openHabInfo.created'
        )}!`
      );
    },
    changeToken(event: Event) {
      this.currentToken = (event.target as HTMLInputElement).value;
    },
    updateToken() {
      if (this.currentToken !== this.token) {
        emitter.emit('token-change', this.currentToken);
      }
    },
  },
  watch: {
    token: function (newVal) {
      this.currentToken = newVal;
    },
  },

  computed: {
    tokenIsCurrent(): boolean {
      return this.token === this.currentToken;
    },
  },
});
