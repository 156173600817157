import tinycolor from 'tinycolor2';
import { ChannelType, IconType, ItemGroupNames } from '@/enums';
import { Item } from '@/types';
import { PluginOptions } from 'vue-toastification';

export const getToastOptions = (): PluginOptions => {
  return {
    pauseOnFocusLoss: false,
    accessibility: {
      toastRole: 'alert',
      closeButtonLabel: "Schließen", // TODO: translate
    }
};
}

export const getHexColor = (item: Item): string => {
  if (getIsOff(item)) {
    return '#E8E8E8';
  }

  if (item.channelType === ChannelType.BRIGHTNESS) {
    return '#ffde36';
  } else {
    const colorState = item.state.split(',');
    const hsbColor = tinycolor(`hsv ${colorState[0]} ${colorState[1]} 100`);

    return hsbColor.toHexString();
  }
};

export const getIsOff = (item: Item): boolean => {
  const state = item.state;

  switch (item.channelType) {
    case ChannelType.BRIGHTNESS: {
      return state === '0' ? true : false;
    }
    case ChannelType.COLOR: {
      const hsbValues = state.split(',');
      return hsbValues[2] === '0' ? true : false;
    }
    default:
      return false;
  }
};

export const getOpacity = (item: Item): number => {
  if (getIsOff(item)) return 1;

  const state = item.state;

  switch (item.channelType) {
    case ChannelType.BRIGHTNESS: {
      return Number(state) / 100;
    }
    case ChannelType.COLOR: {
      const brightness = state.split(',');
      return Number(brightness[2]) / 100;
    }
    default:
      return 1;
  }
};

export const IconToItemGroupNameMapping = {
  [IconType.SWITCH]: ItemGroupNames.ALL_LAMPS_AND_LIGHTSTRIPS_SWITCH,
  [IconType.BRIGHTNESS]: ItemGroupNames.ALL_LAMPS_AND_LIGHTSTRIPS_BRIGHTNESS,
  [IconType.COLOR]: ItemGroupNames.ALL_COLOR_LAMPS_AND_LIGHTSTRIPS_COLOR,
  [IconType.COLOR_TEMPERATURE]:
    ItemGroupNames.ALL_COLOR_LAMPS_AND_LIGHTSTRIPS_COLOR_TEMPERATURE,
} as { [key in IconType]: ItemGroupNames };

export const scrollToElement = (
  target: string | Element,
  offset = 0,
  container: Element | string = window as any
): boolean => {
  const element =
    typeof target === 'string' ? document.querySelector(target) : target;

  if (!element) return false;

  const containerElement =
    typeof container === 'string'
      ? document.querySelector(container)
      : container;

  if (!containerElement) return false;

  // Get the elements to the top of the screen.
  const elementTop = element.getBoundingClientRect().top;
  // Get amount that has already been scrolled.
  const pageOffset =
    (containerElement as any).pageYOffset ||
    (containerElement as any).scrollTop;

  containerElement.scrollTo({
    top: elementTop + pageOffset - offset,
    behavior: 'smooth',
  });

  return true;
};
