import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49b6af29"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between items-center mb-3" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "font-bold text-xl font-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ActionLogItem = _resolveComponent("ActionLogItem")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('actionLog.title')), 1),
        _withDirectives(_createVNode(_component_Icon, {
          class: "text-sm ml-3",
          type: _ctx.IconType.INFO_CIRCLE,
          title: _ctx.$t('actionLog.titleTooltip'),
          tabindex: "0",
          "aria-label": _ctx.$t('actionLog.titleTooltip')
        }, null, 8, ["type", "title", "aria-label"]), [
          [_directive_tippy]
        ])
      ]),
      _withDirectives(_createVNode(_component_AppButton, {
        iconType: _ctx.IconType.X,
        label: _ctx.$t('actionLog.clear'),
        onClick: _ctx.clearActionLog,
        ariaLabel: _ctx.$t('actionLog.clearActionLog')
      }, null, 8, ["iconType", "label", "onClick", "ariaLabel"]), [
        [_vShow, _ctx.logItems.length]
      ])
    ]),
    _createVNode(_TransitionGroup, {
      name: "list",
      tag: "div"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleLogItems, (logItem) => {
          return _withDirectives((_openBlock(), _createBlock(_component_ActionLogItem, {
            key: logItem.timestamp,
            logItem: logItem
          }, null, 8, ["logItem"])), [
            [_vShow, _ctx.logItems.length]
          ])
        }), 128))
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.$t('actionLog.noItems')), 513), [
      [_vShow, !_ctx.logItems.length]
    ])
  ]))
}