export enum IconType {
  SUCCESS = 'success',
  ERROR = 'error',
  REFRESH = 'refresh',
  COPY = 'copy',
  HELP = 'help',
  CHEVRON_DOWN = 'chevronDown',
  CHEVRON_UP = 'chevronUp',
  EXTERNAL_LINK = 'externalLink',
  X = 'x',
  BULB = 'bulb',
  CHECK = 'check',
  CHEVRON_DOWN_SMALL = 'chevronDownSmall',
  CHEVRON_UP_SMALL = 'chevronUpSmall',
  INFO_CIRCLE = 'infoCircle',
  SWITCH = 'switch',
  BRIGHTNESS = 'brightness',
  COLOR = 'color',
  COLOR_TEMPERATURE = 'color_temperature',
  LOGIN = 'login',
  EXPAND = 'expand',
}

export enum ActionLogItemType {
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum ThingStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum ItemType {
  SWITCH = 'Switch',
  DIMMER = 'Dimmer',
  COLOR = 'Color',
  GROUP = 'Group',
}

export enum ChannelType {
  SWITCH = 'switch',
  BRIGHTNESS = 'brightness',
  COLOR = 'color',
  COLOR_TEMPERATURE = 'color_temperature',
}

export enum Language {
  DE = 'de',
  EN = 'en',
}

export enum ErrorType {
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  INVALID_ROOM_ID = 'INVALID_ROOM_ID',
  ITEM_NOT_FOUND = 'ITEM_NOT_FOUND',
  INVALID_COMMAND = 'INVALID_COMMAND',
}

export enum StateChangeType {
  ON = 'ON',
  OFF = 'OFF',
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
  BRIGHTNESS = 'BRIGHTNESS',
  COLOR = 'COLOR',
}

export enum LampLabels {
  'LAMP_FRONT_LEFT' = 'HueLampFrontLeft',
  'LAMP_FRONT_RIGHT' = 'HueLampFrontRight',
  'LAMP_BACK_LEFT' = 'HueLampBackLeft',
  'LAMP_BACK_RIGHT' = 'HueLampBackRight',
  'LIGHTSTRIP_LEFT_TOP' = 'HueLightstripLeftTop',
  'LIGHTSTRIP_LEFT_DOWN' = 'HueLightstripLeftDown',
  'LIGHTSTRIP_RIGHT_TOP' = 'HueLightstripRightTop',
  'LIGHTSTRIP_RIGHT_DOWN' = 'HueLightstripRightDown',
  'TABLE_LAMP' = 'HueLampLittleTable',
}

export enum ItemGroupNames {
  'ALL_LAMPS_AND_LIGHTSTRIPS_SWITCH' = 'AllLampsAndLightstrips_Switch',
  'ALL_LAMPS_AND_LIGHTSTRIPS_BRIGHTNESS' = 'AllLampsAndLightstrips_Brightness',
  'ALL_COLOR_LAMPS_AND_LIGHTSTRIPS_COLOR' = 'AllColorLampsAndLightstrips_Color',
  'ALL_COLOR_LAMPS_AND_LIGHTSTRIPS_COLOR_TEMPERATURE' = 'AllColorLampsAndLightstrips_ColorTemperature',
}
