import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-10 max-w-3xl mx-auto" }
const _hoisted_2 = ["aria-label", "title"]
const _hoisted_3 = { class: "flex flex-col md:flex-row md:items-end gap-3" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "flex-1 flex items-end gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppInput, {
      label: `${_ctx.$t('openHabInfo.virtualLabel')} OpenHAB Server`,
      value: _ctx.OPENHAB_URL,
      readonly: true,
      copyToastMessage: `OpenHAB Server Url ${_ctx.$t('openHabInfo.copied')}!`,
      class: "w-full md:w-1/2"
    }, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: "text-sm",
          "aria-label": _ctx.$t('openHabInfo.openHabServerHint'),
          title: _ctx.$t('openHabInfo.openHabServerHint')
        }, [
          _createVNode(_component_Icon, {
            type: _ctx.IconType.INFO_CIRCLE
          }, null, 8, ["type"])
        ], 8, _hoisted_2)), [
          [_directive_tippy]
        ])
      ]),
      _: 1
    }, 8, ["label", "value", "copyToastMessage"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AppInput, {
        label: `${_ctx.$t('openHabInfo.roomId')} / ${_ctx.$t('openHabInfo.apiToken')}`,
        value: _ctx.currentToken,
        onInput: _ctx.changeToken,
        copyToastMessage: `${_ctx.$t('openHabInfo.roomId')} / ${_ctx.$t(
          'openHabInfo.apiToken'
        )} ${_ctx.$t('openHabInfo.copied')}!`,
        class: "w-full md:w-1/2"
      }, {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("button", {
            class: "text-sm",
            title: _ctx.$t('openHabInfo.roomIdHint')
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('openHabInfo.whatIsThis')), 1)
          ], 8, _hoisted_4)), [
            [_directive_tippy]
          ])
        ]),
        _: 1
      }, 8, ["label", "value", "onInput", "copyToastMessage"]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives((_openBlock(), _createElementBlock("div", null, [
          _createVNode(_component_AppButton, {
            label: _ctx.$t('openHabInfo.enterRoomButtonLabel'),
            onClick: _ctx.updateToken,
            iconType: _ctx.IconType.LOGIN,
            class: "flex-1",
            disabled: _ctx.tokenIsCurrent,
            x: ""
          }, null, 8, ["label", "onClick", "iconType", "disabled"])
        ])), [
          [_directive_tippy, {
            content: _ctx.tokenIsCurrent
              ? _ctx.$t('openHabInfo.roomAlreadyEntered')
              : null,
          }]
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_AppButton, {
            label: _ctx.$t('openHabInfo.newRoomButtonLabel'),
            onClick: _ctx.createNewRoom,
            iconType: _ctx.IconType.REFRESH,
            class: "flex-1"
          }, null, 8, ["label", "onClick", "iconType"])
        ])
      ])
    ])
  ]))
}