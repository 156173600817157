import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "ml-1.5 cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    "aria-label": _ctx.ariaLabel,
    class: _normalizeClass(["flex bg-gray-600 h-10 px-3 rounded-md justify-center items-center text-white cursor-pointer z-20 text-sm sm:text-base transition", {
      'pointer-events-none bg-opacity-30': _ctx.disabled,
    }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.iconType)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          type: _ctx.iconType
        }, null, 8, ["type"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ], 10, _hoisted_1))
}