
import { ThingStatus } from '@/enums';
import { LampTooltip, Thing } from '@/types';
import { defineComponent, PropType } from 'vue';
import { getHexColor, getIsOff, getOpacity } from '@/utils';
import { emitter } from '@/events';

export default defineComponent({
  name: 'Lamp',
  props: {
    lamp: {
      type: Object as PropType<Thing>,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    hexColor(): string {
      // TODO get item dynamic depending on channel type hierarchy
      return getHexColor(this.lamp.items[0]);
    },
    isOff(): boolean {
      if (this.lamp.status === ThingStatus.OFFLINE) {
        return true;
      }

      // TODO get item dynamic depending on channel type hierarchy
      return getIsOff(this.lamp.items[0]);
    },
    opacity(): number {
      // TODO get item dynamic depending on channel type hierarchy
      return getOpacity(this.lamp.items[0]);
    },
    tooltipContent(): LampTooltip {
      // const item = this.lamp.items[0];

      const content = {
        name: this.lamp.label,
        // status: this.capitalizeString(this.lamp.status),
        state: this.lamp.items[0]?.state,
        // itemType: this.capitalizeString(item.itemType),
        // channelType: this.capitalizeString(item.channelType),
      };

      return content;
    },
  },
  methods: {
    capitalizeString(str: string): string {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    highlightThingInList() {
      emitter.emit('highlight-thing', this.lamp.label);
    },
  },
});
