import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex justify-between items-end mb-3" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "relative w-full items-center" }
const _hoisted_5 = ["id", "readonly", "value", "name"]
const _hoisted_6 = ["aria-label", "data-clipboard-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        for: _ctx.inputId,
        class: "font-semibold"
      }, _toDisplayString(_ctx.label), 9, _hoisted_3),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("input", {
        id: _ctx.inputId,
        class: _normalizeClass(["h-10 w-full border border-gray-400 rounded-md p-3 pr-10 cursor-text", { 'bg-gray-100': _ctx.readonly }]),
        readonly: _ctx.readonly,
        value: _ctx.value,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('input', $event))),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('value-change', $event))),
        name: _ctx.label,
        type: "text",
        autocomplete: "off"
      }, null, 42, _hoisted_5),
      _createElementVNode("button", {
        "aria-label": _ctx.$t('openHabInfo.copyInputText', { inputLabel: _ctx.label }),
        class: "cursor-pointer absolute top-2 right-3",
        ref: "copyButton",
        "data-clipboard-text": _ctx.value
      }, [
        _createVNode(_component_Icon, {
          type: _ctx.IconType.COPY,
          class: _normalizeClass(["text-gray-700 bg-white", { 'bg-gray-100': _ctx.readonly }])
        }, null, 8, ["type", "class"])
      ], 8, _hoisted_6)
    ])
  ]))
}