import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-sans" }
const _hoisted_2 = { class: "flex w-full items-center justify-between px-5 pt-12 md:px-12" }
const _hoisted_3 = { class: "px-5 md:px-10 mt-12 pb-20 space-y-10 md:space-y-16" }
const _hoisted_4 = {
  key: 0,
  class: "text-center text-red-700 font-semibold text-lg"
}
const _hoisted_5 = {
  key: 1,
  class: "relative"
}
const _hoisted_6 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-10" }
const _hoisted_7 = ["aria-label"]
const _hoisted_8 = {
  target: "_blank",
  hreflang: "de",
  href: "https://kdb.hdm-stuttgart.de/impressum/"
}
const _hoisted_9 = {
  target: "_blank",
  hreflang: "de",
  href: "https://kdb.hdm-stuttgart.de/datenschutzerklaerung/"
}
const _hoisted_10 = {
  target: "_blank",
  hreflang: "de",
  href: "https://kdb.hdm-stuttgart.de/erklaerung-zur-barrierefreiheit/"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguageSwitch = _resolveComponent("LanguageSwitch")!
  const _component_HelpSection = _resolveComponent("HelpSection")!
  const _component_Intro = _resolveComponent("Intro")!
  const _component_OpenHabInfo = _resolveComponent("OpenHabInfo")!
  const _component_VirtualLabView = _resolveComponent("VirtualLabView")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ThingList = _resolveComponent("ThingList")!
  const _component_ActionLog = _resolveComponent("ActionLog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LanguageSwitch),
        _createVNode(_component_HelpSection)
      ])
    ]),
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Intro),
        _createVNode(_component_OpenHabInfo, {
          token: _ctx.token,
          onCreateNewRoom: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setupLab(true)))
        }, null, 8, ["token"]),
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.things.length && !_ctx.error)
                ? (_openBlock(), _createBlock(_component_VirtualLabView, {
                    key: 0,
                    lamps: _ctx.lamps,
                    class: "-mx-5 md:-mx-10 mt-12",
                    ref: "labView"
                  }, null, 8, ["lamps"]))
                : _createCommentVNode("", true),
              _createVNode(_component_AppButton, {
                ariaLabel: _ctx.$t('general.expandLabView'),
                class: "absolute -right-3 top-4",
                iconType: _ctx.IconType.EXPAND,
                label: _ctx.$t('general.fullScreen'),
                onClick: _ctx.expandLabView
              }, null, 8, ["ariaLabel", "iconType", "label", "onClick"])
            ])),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.things.length && _ctx.itemGroups)
            ? (_openBlock(), _createBlock(_component_ThingList, {
                key: 0,
                things: _ctx.things,
                itemGroups: _ctx.itemGroups
              }, null, 8, ["things", "itemGroups"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ActionLog)
        ])
      ])
    ]),
    _createElementVNode("footer", null, [
      _createElementVNode("nav", {
        "aria-label": _ctx.$t('footer.nav')
      }, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('footer.imprint')), 1)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.$t('footer.privacy')), 1)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.$t('footer.ezb')), 1)
          ])
        ])
      ], 8, _hoisted_7)
    ])
  ]))
}