
import { defineComponent, PropType } from 'vue';
import Thing from './Thing.vue';
import Icon from './Icon.vue';
import ItemGroups from './ItemGroups.vue';
import { Item, Thing as ThingType } from '@/types';
import { IconType } from '@/enums';

export default defineComponent({
  name: 'ThingList',
  components: {
    Thing,
    ItemGroups,
    Icon,
  },
  props: {
    things: {
      type: Object as PropType<ThingType[]>,
      required: true,
    },
    itemGroups: {
      type: Object as PropType<Item[]>,
      required: true,
    },
  },

  data() {
    return {
      IconType,
    };
  },
});
