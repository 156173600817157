import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      "aria-label": `${
        _ctx.shouldShowBody ? _ctx.$t('general.close') : _ctx.$t('general.open')
      } ${_ctx.$t('helpSection.expandableContentOf')} ${_ctx.title}`,
      class: _normalizeClass(["bg-gray-200 w-full p-5 cursor-pointer text-left rounded-md", { 'rounded-b-none rounded-t-md': _ctx.shouldShowBody }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShouldShowBody && _ctx.toggleShouldShowBody(...args)))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
        (!_ctx.shouldShowBody)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              type: _ctx.IconType.CHEVRON_DOWN
            }, null, 8, ["type"]))
          : (_openBlock(), _createBlock(_component_Icon, {
              key: 1,
              type: _ctx.IconType.CHEVRON_UP
            }, null, 8, ["type"]))
      ])
    ], 10, _hoisted_2),
    _withDirectives(_createElementVNode("p", {
      class: "bg-gray-200 p-5 mb-5 rounded-t-none rounded-b-md",
      innerHTML: _ctx.body
    }, null, 8, _hoisted_5), [
      [_vShow, _ctx.shouldShowBody]
    ])
  ]))
}