
import { defineComponent, PropType } from 'vue';
import { Item } from '@/types';
import ItemGroup from './ItemGroup.vue';
import { IconType } from '@/enums';
import Icon from './Icon.vue';

export default defineComponent({
  name: 'ItemGroups',
  components: {
    ItemGroup,
    Icon,
  },
  props: {
    itemGroups: {
      type: Object as PropType<Item[]>,
      required: true,
    },
  },
  data() {
    return {
      IconType,
    };
  },
});
