import { BASE_URL } from './config';
import { APIResponse, ErrorResponse } from './types';

export const init = async (
  token?: string
): Promise<APIResponse | ErrorResponse> => {
  const response = await fetch(BASE_URL + '/start', {
    method: 'POST',
    body: token,
  });

  return await response.json();
};
