import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("a", {
    class: "bg-gray-200 w-full p-5 mb-5 rounded-md cursor-pointer flex justify-between",
    href: _ctx.link,
    target: "_blank"
  }, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_Icon, {
      type: _ctx.IconType.EXTERNAL_LINK
    }, null, 8, ["type"])
  ], 8, _hoisted_1))
}