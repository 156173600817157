
import { defineComponent, PropType } from 'vue';
import { Thing as ThingType } from '@/types';
import Item from './Item.vue';
import { IconType, ChannelType } from '@/enums';
import { emitter } from '@/events';
import { Handler } from 'mitt';
import { scrollToElement } from '@/utils';
import Icon from './Icon.vue';

export default defineComponent({
  name: 'Thing',
  components: {
    Item,
    Icon,
  },
  props: {
    thing: {
      type: Object as PropType<ThingType>,
      required: true,
    },
  },
  data() {
    return {
      IconType,
      ChannelType,
      isHighlighted: false,
      isOpen: false,
    };
  },
  methods: {
    highlightThingHandler(lampLabel: string) {
      const isHighlighted = this.thing.label === lampLabel;
      if (isHighlighted) {
        scrollToElement(this.$refs.thing as HTMLElement, 20);
        (this.$refs.thing as HTMLElement).focus({ preventScroll: true });
      }

      this.isHighlighted = isHighlighted;
    },

    onClick() {
      this.isOpen = !this.isOpen;
    },
  },
  created() {
    emitter.on('highlight-thing', this.highlightThingHandler as Handler);
  },
  beforeUnmount() {
    emitter.off('highlight-thing', this.highlightThingHandler as Handler);
  },
});
