import { renderSlot as _renderSlot, withKeys as _withKeys, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return _withDirectives((_openBlock(), _createElementBlock("g", {
    "aria-label": _ctx.lamp.label,
    tabindex: "0",
    role: "listitem",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.highlightThingInList && _ctx.highlightThingInList(...args))),
    onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.highlightThingInList && _ctx.highlightThingInList(...args)), ["enter"])),
    class: "cursor-pointer focus:outline-black rounded-md"
  }, [
    _renderSlot(_ctx.$slots, "default", {
      color: _ctx.hexColor,
      isOff: _ctx.isOff,
      opacity: _ctx.opacity
    })
  ], 40, _hoisted_1)), [
    [_directive_tippy, {
      content: `
        ${_ctx.tooltipContent.name}<br/>
        State: ${_ctx.tooltipContent.state}<br/>
        `,
      allowHTML: true,
      placement: 'right',
    }]
  ])
}