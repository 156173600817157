import { StateChangeType } from '@/enums';
import { ActionLogError } from '@/types';

export class ActionLogItem {
  timestamp: number;
  itemName?: string;
  stateChangeType?: StateChangeType;
  state?: string;
  error?: ActionLogError;

  constructor(
    timestamp: number,
    itemName?: string,
    stateChangeType?: StateChangeType,
    state?: string,
    error?: ActionLogError
  ) {
    this.timestamp = timestamp;
    this.itemName = itemName;
    this.stateChangeType = stateChangeType;
    this.state = state;
    this.error = error;
  }
}
