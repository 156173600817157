import { OPENHAB_URL } from '@/config';
import { ErrorType } from '@/enums';
import { FAQItem } from '@/types';

export default {
  general: {
    open: 'Öffne',
    close: 'Schließe',
    virtualPUXlab: 'Virtuelles PUX-Labor',
    virtualPUXlabDescription: `Das virtuelle PUX-Labor is ein interaktives, virtuelles Wohnzimmer mit Lampen 
    und Lichtbändern, welches eine virtuelle Version des realen PUX-Labors an der Hochschule der Medien in 
    Stuttgart repräsentiert.`,
    fullScreen: 'Vollbild',
    expandLabView: 'Laboransicht erweitern auf Vollbildmodus.',
  },
  intro: {
    title: 'Willkommen im Virtuellen PUX-Labor!',
    paragraph1: `Das virtuelle PUX-Labor ist eine virtuelle Version des echten PUX-Labors der
    Hochschule der Medien. Das Labor ist wie ein typisches Wohnzimmer gestaltet, in dem verschiedene 
    Smarthome-Geräte zu finden sind, z.B. smarte Lampensysteme wie Philips Hue oder Ikea Trådfri.`,
    paragraph2: `Ziel dieses Labors ist es, zu lernen, wie die Smarthome-Geräte mit der OpenHAB-API gesteuert 
    werden können. Der Zugriff auf den virtuellen OpenHAB-Server erfolgt über folgende Adresse:`,
    paragraph3: `Die Raum-ID, die unten aufgeführt ist, kann mit anderen Studierenden geteilt werden, um 
    gemeinsam im selben virtuellen Labor zu arbeiten. Verwende die Raum-ID als API-Token (Basic Auth) in deinen 
    OpenHAB-Anfragen.`,
    paragraph4: `Um die smarten Glühbirnen zu steuern, sende einfach eine entsprechende HTTP-Anfrage zum 
    virtuellen OpenHAB-Server. Du kannst z.B. die Lichtfarbe oder die Helligkeit verändern und den Effekt 
    im virtuellen PUX-Labor betrachten. Um Informationen über die Geräte zu erhalten, führe deinen Mauszeiger
    einfach über die Lampen im virtuellen Labor oder schaue in der "Things & Items" Liste nach.`,
    helpSectionHint: [
      'Du hast noch keine Erfahrung mit OpenHAB?',
      'Schau dir den',
      'Hilfebereich',
      'oben rechts an.',
    ],
  },
  helpSection: {
    title: 'Hilfebereich',
    help: 'Hilfe',
    introText: `In diesem Bereich findest du einige Ressourcen, die dir helfen sollen zu verstehen, 
    wie dieses virtuelle Labor genutzt werden kann.`,
    video: 'Erklärvideo',
    videoText: `Dieses Video soll dir einen ersten Einblick über das virtuelle Labor geben und zeigen, 
    wie man es nutzen kann.`,
    faq: 'Häufig gestellte Fragen',
    expandableContentOf: 'aufklappbaren Inhalt von',
    faqItems: [
      {
        title: 'Wie steuere ich die Lampen?',
        body: `Um den Zustand der Lampen zu verändern, muss eine entsprechende Anfrage an den virtuellen OpenHAB-Server
        gesendet werden. Diese kann bspw. von deiner eigens entwickelten Smarthome-Anwendung kommen.<br/><br/>
        Wichtig dabei ist, dass die Raum-ID in der Anfrage als API-Token im Authentiction Header im Basic Auth-Format 
        (Token als Username, Passwort leer) verwendet wird, damit du den veränderten Zustand auch in deiner virtuellen PUX-Labor-Instanz siehst.<br/><br/>
        Um eine bestimmte Lampe (Thing) anzusteuern, brauchst du ein dazugehöriges Item, das kann bspw. ein Item vom Typ 
        "Dimmer" mit dem Channeltyp "Brightness" sein, mit dem du die Helligkeit einer Lampe verändern kannst. Welche 
        Commands du pro Itemtyp verwenden kannst, findest du in der <a href="https://www.openhab.org/docs/concepts/items.html#items" 
        target="blank" class="underline">OpenHAB-Dokumentation</a>.
        <br/><br/>
        
        <strong>Beispiel, um die Helligkeit der HueLampLittleTable-Lampe zu verringern:</strong><br/>
        <code class="font-mono">
          curl -X POST --header "Content-Type: text/plain" --header "Accept: application/json" -d "DECREASE"
          "${OPENHAB_URL}/rest/items/HueLampLittleTable_Brightness" -u "&lt;API_TOKEN&gt;:"
        </code>

        <br/><br/>
        <strong>Beispiel, um die Farbe der HueLampFrontLeft-Lampe mit dem HSB-Format (Hue, Saturation, Brightness) zu einem 
        kräftigen Rot zu verändern:</strong><br/>
        <code class="font-mono">
          curl -X POST --header "Content-Type: text/plain" --header "Accept: application/json" -d "0,100,100"
          "${OPENHAB_URL}/rest/items/HueLampFrontLeft_Color" -u "&lt;API_TOKEN&gt;:"
        </code>
        `,
      },
      {
        title: 'Was ist die Raum-ID und wie soll sie genutzt werden?',
        body: `Die Raum-ID ist eine Methode, um die Wohnzimmer-Instanz zu identifizieren, in der du 
        dich gerade befindest. Wenn du dem Kurs das erste Mal beitretest, erhälst du eine einzigartige Raum-ID. 
        Diese wird persistiert, sodass du mit der ID jederzeit zur jeweiligen Laborinstanz und dessen Zustand
        zurückkehren kannst.<br/><br/>
        Die Raum-ID kann mit anderen Studierenden geteilt werden, um gemeinsam im selben Raum zu arbeiten. 
        Deshalb ist es möglich die Raum-ID zu ändern. Selbst erstellte Raum-IDs funktionieren allerdings 
        nicht, sie müssen vom System generiert worden sein.<br/><br/>
        Es können zudem neue IDs / Tokens generiert werden, um in einem neuen, frischen virtuellen Laborzustand 
        zu arbeiten. Dies kann außerdem hilfreich sein, um in mehreren Räumen parallel zu arbeiten und die jeweiligen 
        Zustände getrennt zu halten (bspw. ein Gruppenraum und ein individueller Raum). Daher macht es auch Sinn, sich 
        die Raum-IDs irgendwo gesondert zu merken, um zu einem bestimmten Raum zurückkehren zu können.<br/><br/>
        Wenn du eine Lampe in deinem virtuellen PUX-Labor, welches durch die Raum-ID identifiziert wird, steuern 
        möchtest, nutze die Raum-ID einfach als API-Token (Basic Auth) innerhalb deiner OpenHAB-Anfrage.`,
      },
      {
        title: 'Wo finde ich die API-Dokumentation von OpenHAB?',
        body: `Gehe zur <a href="https://demo.openhab.org/developer/api-explorer" target="blank" class="underline">
        OpenHAB-Demo</a> und logge dich (Schloss-Icon "Unlock Administration" unten im Menü in der Seitenleiste) mit 
        dem Demo-Nutzer ("demo" als Nutzername und Passwort) ein. Danach findest du links den Menüpunkt 
        "Developer Tools" ➔ "API Explorer".`,
      },
      {
        title: 'Was ist der virtuelle OpenHAB-Server?',
        body: `Der virtuelle OpenHAB-Server soll den echten OpenHAB-Server des PUX-Labors simulieren. Durch 
        entsprechende Anfragen an den virtuellen OpenHAB-Server können die Lampen im virtuellen PUX-Labor 
        angesteuert werden. Es existieren folgende REST-Endpunkte des virtuellen OpenHAB-Servers:
        <br/><br/>
        <ul>
        <li> -  GET <span class="font-mono">/rest/things</span> </li>
        <li> - GET <span class="font-mono">/rest/items</span> </li>
        <li> - POST <span class="font-mono">/rest/item/:itemName</span> </li>
        </ul>
        <br/>
        Bitte beachte, dass der echte OpenHAB-Server nur über HTTP erreichbar ist, der virtuelle 
        OpenHAB-Server ist jedoch verschlüsselt und über HTTPS zu erreichen.`,
      },
      {
        title: 'Was sind Things & Items?',
        body: `Things und Items sind Entitäten von OpenHAB. Diese solltest du kennenlernen, um mit dem 
        virtuellen Labor interagieren und dessen Lampen ansteuern zu können. Schaue dir hierzu einfach die 
        <a href="https://www.openhab.org/docs/" target="blank" class="underline">Dokumentation von OpenHAB</a> an, 
        um die Logik zu verstehen. In der Antwort der nachfolgenden Frage sind Beispiele enthalten, wie man mit 
        den Items interagieren kann, um Lampenzustände zu verändern.
        <br/><br/>
        <ul>
        <li>- <strong>Things:</strong> Repräsentieren die physischen Geräte (z.B. HueLampLittleTable).</li>
        <li>- <strong>Items:</strong> Repräsentieren Informationen über die Geräte (z.B. den Zustand) und 
        bestimmen, wie die Geräte angesteuert werden können (z.B. beim Typ "Dimmer" mit Prozentwerten oder bei 
        "Color" mit Werten im HSB-Format).</li>
        <li>- <strong>Channels:</strong> Repräsentieren die Verbindung von Things und Items und ermöglichen die Steuerung 
        von bestimmten Funktionalitäten der Geräte (z.B. Typ "Brightness" oder "Color").</li>
        </ul>
        `,
      },
      {
        title: 'Was sind Item Groups?',
        body: `Item Groups sind Items, die andere Items umfassen. Mit Item Groups können dementsprechend mehrere Items 
        und damit mehrere Lampen gleichzeitig gesteuert werden. Zum Beispiel ist es möglich, mit der Item Group 
        AllLampsAndLightstrips_Brightness die Helligkeit aller Lampen auf den gleichen Wert zu setzen.
        `,
      },
      {
        title: 'Wie kann ich mit dem virtuellen Labor interagieren?',
        body: `Es gibt verschiedene Bereiche des virtuellen Labors, die unterschiedliche Informationen und Interaktionen bieten:
        <br/><br/>
        <ul>
        <li>
          <strong>Sprache wechseln:</strong> In der linken oberen Ecke findest du eine Möglichkeit zwischen Deutsch und Englisch 
          zu wechseln. Eigene OpenHAB-Begriffe wie Thing, Item und State etc. wurden bewusst nicht übersetzt.
        </li>
        <br/>
        <li>
          <strong>Einführungstext:</strong> Ganz oben findest du eine Einführung in das virtuelle PUX-Labor, welche kurz erklärt, 
          was das virtuelle PUX-Labor ist und wie es genutzt werden kann. 
        </li>
        <br/>
        <li>
          <strong>Textfelder:</strong> Unter dem Einführungstext findest du in den Textfeldern einmal die Adresse des 
          virtuellen OpenHAB-Servers, an die Anfragen geschickt werden können. Diese kann kopiert, aber nicht verändert werden.<br/>
          Im zweiten Textfeld findet sich die Raum-ID, die als API-Token in den OpenHAB-Anfragen verwendet werden soll, um die 
          aktuelle virtuelle PUX-Labor-Instanz eindeutig zu identifizieren. Diese wird persistiert, sodass du mit der ID jederzeit 
          zur jeweiligen Laborinstanz und dessen Zustand zurückkehren kannst. Die Raum-ID kann mit anderen geteilt und verändert 
          werden, um im selben virtuellen Raum zu arbeiten. Es muss jedoch eine vom System generierte ID sein. Es können zudem neue 
          IDs / Tokens generiert werden, um in einem neuen, frischen virtuellen Laborzustand zu arbeiten. Dies kann außerdem 
          hilfreich sein, um in mehreren Räumen parallel zu arbeiten und die jeweiligen Zustände getrennt zu halten (bspw. ein 
          Gruppenraum und ein individueller Raum) Daher macht es auch Sinn, sich die Raum-IDs irgendwo gesondert zu merken, um 
          zu einem bestimmten Raum zurückkehren zu können.
        </li>
        <br/>
        <li>
          <strong>Abbildung des virtuellen Labors:</strong> Der Hauptbereich des virtuellen PUX-Labors ist dessen virtuelle Abbildung. 
          In dieser kannst du den Zustand der Lampen in deiner virtuellen PUX-Labor-Instanz visuell betrachten. Wenn du deinen 
          Mauszeiger über die Lampen führst, erhälst du den Namen und den Status der Lampe. Um weitere wichtige Informationen zu 
          erhalten, wie bspw. die dazugehörigen Items, um die Lampe anzusteuern, klicke auf die Lampe und du wirst automatisch zum 
          hervorgehobenen Thing und dessen Items in der Liste navigiert.<br/>
          Da eine exakte Darstellung der Lampenzustände im virtuellen Raum nicht möglich ist, wird hier kurz die Darstellung von 
          einigen besonderen Zuständen erklärt: <br/>
          <ul>
          <li>- Wenn eine Lampe aus ist, hat sie keinen Schein und der Lampenschirm ist hellgrau gefüllt.</li>
          <li>- Je geringer die Helligkeit, umso durchsichtiger der Lampenschirm sowie der Schein.</li>
          <li>- Lampen, bei denen nur die Helligkeit, aber nicht die Farbe kontrolliert werden kann, werden mit einer gelblichen 
          Farbe und einem gelben Schein dargestellt, da ein weißer Schein bei dem weißen Hintergrund nicht gut erkennbar wäre.</li>
          <li>- Items mit dem Channeltyp "Color Temperature" können im virtuellen Labor leider nicht angesteuert bzw. dargestellt werden, 
          d.h. die Veränderung dieser Zustände hat daher keine Auswirkung auf die Darstellung.</li>
          </ul>
        </li>
        <br/>
        <li>
          <strong>Things & Items Liste:</strong> Diese Liste enthält textuelle Informationen zu den OpenHAB Things und den 
          dazugehörigen Items. Alle Informationen, welche zum Ansteuern der Lampen wichtig und relevant sind, sind dort dargestellt. 
          Außerdem kann der genaue Zustand ergänzend zur visuellen Darstellung in der Abbildung in textueller Form eingesehen werden.
        </li>
        <br/>
        <li>
          <strong>Aktionsprotokoll:</strong> Im Aktionsprotokoll werden die Anfragen protokolliert, die den Zustand der Items verändern. 
          Es wird jeweils festgehalten, wie der Zustand von welchem Item zu welchem Zeitpunkt mit welchem Wert verändert wurde. Außerdem 
          werden hier auch fehlgeschlagene Anfragen dokumentiert. Beim Klicken auf den "Leeren"-Knopf, kannst du die bisherigen 
          Einträge im Protokoll löschen.
        </li>
        <br/>
        <li>
          <strong>Hilfebereich:</strong> In diesem Bereich, in dem du dich gerade befindest, findest du einige wichtige Ressourcen, 
          die dir bei der Nutzung des virtuellen PUX-Labors helfen könnten, bspw. ein Erklärvideo, das dir alle Interaktionsmöglichkeiten 
          und Funktionalitäten darstellt.
        </li>
        </ul>`,
      },
      {
        title: 'Was ist das Aktionsprotokoll?',
        body: `Im Aktionsprotokoll werden die Anfragen protokolliert, die den Zustand der Items verändern. Es werden die 
        maximal 30 neuesten angezeigt. Es wird jeweils festgehalten, wie der Zustand von welchem Item zu welchem Zeitpunkt 
        mit welchem Wert verändert wurde. Außerdem werden hier auch fehlgeschlagene Anfragen dokumentiert. Das Aktionsprotokoll 
        wird nicht persistiert, d.h. beim Neuladen der Seite wird es geleert.`,
      },
    ] as FAQItem[],
    otherResources: 'Weitere Ressourcen',
    website: 'Webseite',
    documentation: 'Dokumentation',
  },
  actionLog: {
    title: 'Aktionsprotokoll',
    titleTooltip: `Im Aktionsprotokoll werden die HTTP-Anfragen protokolliert, die den Zustand der Items verändern. 
    Es wird jeweils festgehalten, wie der Zustand von welchem Item zu welchem Zeitpunkt mit welchem Wert verändert wurde. Außerdem 
    werden hier auch fehlgeschlagene Anfragen dokumentiert.`,
    noItems: 'Keine Aktionsprotokolleinträge vorhanden.',
    lampSingularMessage: 'Lampe wurde',
    lampPluralMessage: 'Lampen wurden',
    lampTurnedOnMessage: 'angeschaltet',
    lampTurnedOffMessage: 'ausgeschaltet',
    brightnessIncreaseMessage: 'Helligkeit wurde erhöht auf',
    brightnessDecreaseMessage: 'Helligkeit wurde verringert auf',
    brightnessChangeMessage: 'Helligkeit wurde geändert auf',
    colorChangeMessage: 'Farbe wurde geändert zu',
    clear: 'Leeren',
    clearActionLog: 'Aktionsprotokoll leeren',
  },
  items: {
    colorTemperatureItemHint: `Items vom Channeltyp "Color Temperature" können im virtuellen PUX-Labor 
    nicht angesteuert werden.`,
    itemName: 'Item Name',
    itemGroupName: 'Item Group Name',
    copyItemName: '@:items.itemName {itemName} kopieren',
    copyItemGroupName: '@:items.itemGroupName {itemGroupName} kopieren',
    goToItemGroup: 'Zur Item Group {itemGroup} gehen',
    listTitleTooltip: `Diese Liste enthält textuelle Informationen zu den OpenHAB Things und den 
    dazugehörigen Items. Alle Informationen, welche zum Ansteuern der Lampen wichtig und relevant 
    sind, sind hier dargestellt. Mit entsprechenden HTTP-Anfragen an die Items, können die Lampen 
    (Things) beeinflusst werden. Beispiele hierfür findest du im Hilfebereich.`,
    itemGroupTooltip: `Item Groups sind Items, die andere Items umfassen. Mit Item Groups können 
    dementsprechend mehrere Items und damit mehrere Lampen gleichzeitig gesteuert werden. Zum Beispiel 
    ist es möglich, mit der Item Group AllLampsAndLightstrips_Brightness die Helligkeit aller Lampen 
    auf den gleichen Wert zu setzen.`,
  },
  openHabInfo: {
    roomId: 'Raum-ID',
    apiToken: 'API-Token',
    whatIsThis: 'Was ist das?',
    newRoomButtonLabel: 'Neuer Raum',
    enterRoomButtonLabel: 'Raum betreten',
    roomAlreadyEntered:
      'Du befindest dich bereits in dem Raum mit der angegebenen ID.',
    roomIdHint: `Die Raum-ID ist ein eindeutiger Bezeichner für die virtuelle Laborinstanz, in der du arbeitest.
    Nutze ihn als API-Token (Basic Auth) in deinen OpenHAB-Anfragen. Du kannst die ID mit anderen teilen, um 
    zusammen zu arbeiten.`,
    openHabServerHint: `Hinweis: Der virtuelle OpenHAB-Server ist über HTTPS erreichbar. Anfragen an den echten 
    OpenHAB-Server im PUX-Labor müssen an dessen Adresse gehen und über HTTP erfolgen.`,
    virtualLabel: 'Virtueller',
    copied: 'kopiert',
    created: 'erstellt',
    roomEntered: 'Raum betreten!',
    copyInputText: 'Textfeldinhalt von {inputLabel} kopieren',
    invalidRoomId: 'Ungültige Raum-ID!',
  },
  languageSwitch: {
    en: 'Englisch',
    de: 'Deutsch',
    languageDropdown: 'Sprachauswahl-Dropdown',
    changeLanguageTo: 'Sprache ändern zu',
  },
  errors: {
    error: 'Fehler',
    [ErrorType.CONNECTION_ERROR]:
      'Verbindungsfehler: Daten des virtuellen Labors können nicht abgerufen werden.',
    [ErrorType.INVALID_ROOM_ID]: `Ungültige Raum-ID: Bitte verwenden Sie gültige, vom System erstellte 
      IDs oder erstellen Sie einen neuen Raum.`,
    [ErrorType.ITEM_NOT_FOUND]: 'Item nicht gefunden:',
    [ErrorType.INVALID_COMMAND]: 'Ungültiger Befehl:',
  },
  footer: {
    nav: "Fußmenü",
    imprint: "Impressum",
    privacy: "Datenschutz",
    ezb: "Barrierefreiheit",
  },
};
