
import { defineComponent, PropType } from 'vue';
import Icon from './Icon.vue';
import { IconType } from '@/enums';

export default defineComponent({
  name: 'AppButton',
  components: { Icon },
  props: {
    ariaLabel: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconType: {
      type: String as PropType<IconType>,
      required: false,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      IconType,
    };
  },
});
