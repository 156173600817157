
import { IconToItemGroupNameMapping } from '@/utils';
import { IconType, ItemGroupNames } from '@/enums';
import { PropType } from '@vue/runtime-core';
import { emitter } from '@/events';
import Icon from './Icon.vue';

const groupIconMapping = [
  [ItemGroupNames.ALL_LAMPS_AND_LIGHTSTRIPS_SWITCH, IconType.SWITCH],
  [ItemGroupNames.ALL_LAMPS_AND_LIGHTSTRIPS_BRIGHTNESS, IconType.BRIGHTNESS],
  [ItemGroupNames.ALL_COLOR_LAMPS_AND_LIGHTSTRIPS_COLOR, IconType.COLOR],
  [
    ItemGroupNames.ALL_COLOR_LAMPS_AND_LIGHTSTRIPS_COLOR_TEMPERATURE,
    IconType.COLOR_TEMPERATURE,
  ],
];

export default {
  components: { Icon },
  props: {
    groups: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },

  data() {
    return {
      IconToItemGroupNameMapping,
    };
  },

  computed: {
    itemGroupIcons(): IconType[] {
      let iconTypes = [];

      for (const [group, icon] of groupIconMapping) {
        if ((this as any).groups.includes(group)) {
          iconTypes.push(icon as IconType);
        }
      }

      return iconTypes;
    },
  },

  methods: {
    highlightItemInList(itemName: string): void {
      emitter.emit('highlight-item', itemName);
    },
  },
};
